import { createStore } from "vuex";
import { ROLES } from "./roles";
import { NewsService } from "@/services/news";
import { ProjectService } from "@/services/project";
import {CategoryService} from "@/services/category";
import { ElLoading } from "element-plus";

const state = {
  sidebarShow: "responsive",
  sidebarMinimize: false,
  initialCategories:[
    {
      name: "Complexity",
      id:'complexity',
      subcategories: [
        { id:"high",name: "high", color: "#333", show: true },
        { id:"medium",name: "medium", color: "#333", show: true },
        { id:"low",name: "low", color: "#333", show: true },
      ],
    },
    {
      name: "Priority",
      id:'priority',
      subcategories: [
        { id:"high",name: "high", color: "#333", show: true },
        { id:"medium",name: "medium", color: "#333", show: true },
        { id:"low",name: "low", color: "#333", show: true },  
      ],
    },
  ],
  categories:[],
  isLoading:false,
  alert: {
    show: false,
    color: "success",
    duration: 5000,
    message: "",
  },
  currentUser: [],
  token: "",
  filters: {
    keyword: "",
    level: [],
    countries: [],
    serviceLine: [],
    period: "",
  },
  allProjects: [],
  currentSelectedProject: "",
  projectNews: [],
  currentProjectsCases: [],
  blobToken: "",
};

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  setAlert(state, config) { 
    state.alert = { ...state.alert, ...config, show: true };
  },
  hideAlert() {
    state.alert.show = false;
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  setCategories(state,val){
    if(val.data){

      state.categories=val
      state.categories.data=state.categories.data.map(el=>({...el,subcategories:el.subcategories.map(el=>({...el,show:true}))}));
      state.categories.data=[...state.categories.data,...state.initialCategories]
    }
},
  setFilter(state, text, start, end, level, countries, services) {
    state.filters["keyword"] = text;
    state.filters["start"] = start;
    state.filters["end"] = end;
    state.filters["level"] = level;
    state.filters["countries"] = countries;
    state.filters["services"] = services;
  },
  setToken(state, token) {
    state.token = token;
  },
  setLoading(state, val) {
    state.isLoading = val;
  },
  setActiveUser(state, user) {
    state.currentUser = user;
  },
  setAllProjects(state, projects) {
    state.allProjects = projects;
  },
  setCurrentSelectedProject(state, project) {
    state.currentSelectedProject = project;
  },
  setProjectNews(state, news) {
    state.projectNews = news;
  },
  setCurrentProjectsCases(state, cases) {
    state.currentProjectsCases = cases;
  },
  setBlobToken(state, token) {
    state.blobToken = token;
  },
};

const actions = {
  async fetchNews({ commit }) {
    const { data } = await NewsService.get_news({
      page: 1,
      count: 10,
    });
    commit("setProjectNews", data.data);
  },
  async fetchProjects({ commit }) {
    const { data } = await ProjectService.get_all_projects({
      page: 1,
    });
    commit("setAllProjects", data.data);
  },
  async fetchCategories({ commit }) {

    commit("setLoading", true);

    const {data}  = await CategoryService.get_categories(state.currentSelectedProject);
    commit("setCategories", data);
      
    commit("setLoading", false);

  },
};

export default createStore({
  state,
  actions,
  mutations,
});
