import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";

export const FILES_ENDPOINTS = {
  getUploadUrl: (container) => `${BASE_URL}api/files/get-upload-url/?container=${container}`,
  getFileUrl: (fileId) => `${BASE_URL}api/files/get-download-url/?id=${fileId}`,
  confirmUrl: (fileId) => `${BASE_URL}api/files/file-confirm/?id=${fileId}`,
  deleteFile: (fileId) => `${BASE_URL}api/files/delete-file/?id=${fileId}`,
  storageToken: `${BASE_URL}api/files/get-storage-token/`,
};

class Files {
  async getUploadUrl(container="news") {
    /* 
      NOTE:
        When uploading the image of a destination keep the below structure:
        container = "destinations" #hardCoded
    */
    const { data } = await request({
      url: FILES_ENDPOINTS.getUploadUrl(container),
      method: "GET",
    });
    return data;
  }

  async UploadFile(file, UploadUrl) {
    try {
      const res = await fetch(UploadUrl, {
        method: "PUT",
        body: file,
        headers: {
            "x-ms-blob-type": "BlockBlob"
        },
      });
  
      if (res.status === 201) {
          return {"success": true};
      }
      console.log("res", res);
      return res;
    } catch (error) {
      console.error(error);
      return error;
    }

  }

  async getFileUrl(fileId) {
    const res = await request({
      url: FILES_ENDPOINTS.getFileUrl(fileId),
      method: "GET",
    });
    return res;
  }

  async getStorageToken() {
    /* 
      Note:
        When you get the token you can access all destinations images by using the bellow url:
        "https://usecaseai.blob.core.windows.net/destinations/{image_id}?{token}
    */
    const res = await request({
      url: FILES_ENDPOINTS.storageToken,
      method: "GET",
    });
    return res;
  }

  async deleteFile(fileId) {
    const res = await request({
      url: FILES_ENDPOINTS.deleteFile(fileId),
      method: "DELETE",
    });
    return res;
  }
}

export const FilesService = new Files();
