<template>
  <router-view></router-view>
  

</template>

<script>

export default {
    name: 'App',
    watch: {
        '$route'(to) {
            document.title = to.meta.title || to.params[to.meta.paramKey] || to.meta.label || to.name || 'Mazars';
        }
    },
   
    components: {  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
