import { AuthService } from "./auth";
import { UsersService } from "@/services/users";
import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";

export const USERS_ENDPOINTS = {
  users: `${BASE_URL}api/users/`,
  userUpdate: `${BASE_URL}api/users/update/`,
  usersRoute: (route) => `${BASE_URL}api/users/${route}/`,
  change_language: `${BASE_URL}api/users/change_lang/`,
  change_category: `${BASE_URL}api/users/change_category/`,
};

class User {
  user = null;
  tokenBearer = "Bearer";

  get() {
    if (this.user) {
      return this.user;
    }
    const savedUser = localStorage.getItem("web_frequent_flyer_user");
    if (savedUser) {
      this.user = JSON.parse(savedUser);
      this.set(this.user);
      return this.user;
    }
  }

  clear() {
    const token = this.getToken();
    localStorage.removeItem("web_frequent_flyer_user");
    this.user = null;
    localStorage.removeItem("access_token");
    localStorage.clear();
    if (token) {
      AuthService.logout(token);
    }
  }

  async set(value) {
    localStorage.setItem("web_frequent_flyer_user", JSON.stringify(value));
    if (value.accessToken) {
      localStorage.setItem("access_token", value.accessToken);
    }
    const userInfo = await UsersService.get_userdata();
    if (!userInfo?.id) {
      return;
    }
    const userData = { ...value, ...userInfo };
    localStorage.setItem("web_frequent_flyer_user", JSON.stringify(userData));
    this.user = userData;
  }

  getToken() {
    return this.get()?.accessToken;
  }

  getAuthorization() {
    return `${this.tokenBearer} ${this.getToken()}`;
  }

  setToken(token) {
    return this.set({ accessToken: token });
  }

  getLanguage() {
    return this.get()?.language;
  }

  setLanguage(language) {
    if (language !== this.getLanguage()) {
      return this.set({ ...this.get(), language });
    }
  }

  isAdministrator() {
    return this.get()?.is_superuser;
  }

  async profileUpdate(payload) {
    /* payload = {
        phone_number: string
        linkedin_url: string
        availability: {"start": string, "end": string}
      } 
      NOTE: At lease one of the fields must be present in the payload.
      Note: Don't include fields that you don't want to update
    */
    if (
      !payload.phone_number &&
      !payload.linkedin_url &&
      !payload.availability
    ) {
      throw new Error(
        "At lease one of the fields must be present in the payload"
      );
    }
    return await request({
      url: USERS_ENDPOINTS.userUpdate,
      method: "POST",
      body: payload,
    });
  }

  async removeAvailability(id) {
    /* payload = {
        id: string
      } 
    */
    if (!id) {
      throw new Error("id must be present in the payload");
    }
    return await request({
      url: USERS_ENDPOINTS.usersRoute("availability"),
      method: "DELETE",
      body: {
        id,
      },
    });
  }

  async removeEngagement(id) {
    /* payload = {
        id: string
      } 
    */
    if (!id) {
      throw new Error("id must be present in the payload");
    }
    return await request({
      url: USERS_ENDPOINTS.usersRoute("engagements"),
      method: "DELETE",
      body: {
        id,
      },
    });
  }

  async addEngagement(start, end, title, employer, description) {
    /* payload = {
        "start": String,
        "end": String,
        "title": String,
        "employer": String,
        "description": String
      } 
    */
    if (!start || !end || !title || !employer || !description) {
      throw new Error("id must be present in the payload");
    }
    return await request({
      url: USERS_ENDPOINTS.usersRoute("engagements"),
      method: "POST",
      body: {
        start,
        end,
        title,
        employer,
        description,
      },
    });
  }
  async change_category(payload) {
    
    const {data}= await request({
      url: USERS_ENDPOINTS.change_category,
      method: "PUT",
      body:payload
    });
    return data;
  }
  async removeEducation(id) {
    /* payload = {
        id: string
      } 
    */
    if (!id) {
      throw new Error("id must be present in the payload");
    }
    return await request({
      url: USERS_ENDPOINTS.usersRoute("educations"),
      method: "DELETE",
      body: {
        id,
      },
    });
  }

  async addEducation(start, end, title, establishment, description, degree) {
    /* payload = {
        "start": String,
        "end": String,
        "title": String,
        "establishment": String,
        "description": String
        "degree": String
      } 
    */
    if (!start || !end || !title || !establishment || !description || !degree) {
      throw new Error("id must be present in the payload");
    }
    return await request({
      url: USERS_ENDPOINTS.usersRoute("educations"),
      method: "POST",
      body: {
        start,
        end,
        title,
        establishment,
        description,
        degree,
      },
    });
  }

  async changeLanguage(payload) {
    return await request({
      url: USERS_ENDPOINTS.change_language,
      method: "PUT",
      body: payload,
    });
  }
}

export const ActiveUser = new User();
