import { BASE_URL } from '@/constants/config';

export const API_ENDPOINTS = {
  mzAuth: `${BASE_URL}auth/mz/authorize?authentication_backend=jwt`,
  mzAuthCallback: `${BASE_URL}auth/mz/callback`,
  lazyTable: `${BASE_URL}api/lazytable/`,
  dashboard: `${BASE_URL}api/dashboard/`,
};

export const DATE_FORMAT = "YYYY-MM-DD";
