import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";

export const SUPPORT_ENDPOINTS = {
  create_project: `${BASE_URL}api/projects/`,
  get_project: (id) => `${BASE_URL}api/projects/${id}`,
  get_all_projects: `${BASE_URL}api/projects/all`,
  update_project: (id) => `${BASE_URL}api/projects/${id}`,
  update_current_project: (id) => `${BASE_URL}api/users/change_project/${id}/`,
  delete_project: (id) => `${BASE_URL}api/projects/${id}`,
  add_users: `${BASE_URL}api/users/add_member/`,
  edit_users_role: `${BASE_URL}api/users/change_role/`,
  remove_user: `${BASE_URL}api/users/remove/`,
};

class Project {
  async create_project(body) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.create_project,
      method: "POST",
      body: body,
    });
    return {
      data,
      res,
    };
  }

  async get_project(form) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.get_project,
      method: "POST",
      formData: form,
    });
    return {
      data,
      res,
    };
  }
  async get_all_projects(payload) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.get_all_projects,
      method: "POST",
      body: payload,
    });
    return {
      data,
      res,
    };
  }
  async update_project(body, id) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.update_project(id),
      method: "PUT",
      body: body,
    });
    return {
      data,
      res,
    };
  }
  async update_current_project(id) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.update_current_project(id),
      method: "PUT",
    });
    return {
      data,
      res,
    };
  }
  async delete_project(id) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.delete_project(id),
      method: "DELETE",
    });
    return {
      data,
      res,
    };
  }
  async addUsersToProject(body) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.add_users,
      method: "POST",
      body,
    });
    return {
      data,
      res,
    };
  }
  async editUsersRole(body) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.edit_users_role,
      method: "PUT",
      body,
    });
    return {
      data,
      res,
    };
  }
  async removeUser(body) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.remove_user,
      method: "PUT",
      body,
    });
    return {
      data,
      res,
    };
  }
}

export const ProjectService = new Project();
