import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";

export const SUPPORT_ENDPOINTS = {
  get_news: `${BASE_URL}api/news/all`,
  create_news: `${BASE_URL}api/news/`,
  update_news: (id) => `${BASE_URL}api/news/${id}/`,
  delete_news: (id) => `${BASE_URL}api/news/${id}/`,
  get_project: (id) => `${BASE_URL}api/project/${id}`,
  get_all_projects: `${BASE_URL}api/projects/all`,
  get_specific_news: (id) => `${BASE_URL}api/news/detail/${id}/`,
};

class News {
  async get_news(payload) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.get_news,
      method: "POST",
      body: payload,
    });
    return {
      data,
      res,
    };
  }

  get_specific_news = async (id) => {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.get_specific_news(id),
      method: "GET",
    });
    return {
      data,
      res,
    };
  };

  async create_news(formData) {
    console.log("formData", formData);
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.create_news,
      method: "POST",
      formData,
    });
    return {
      data,
      res,
    };
  }
  
  async update_news(id, formData) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.update_news(id),
      method: "PUT",
      formData,
    });
    return {
      data,
      res,
    };
  }

  async delete_news(id) {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.delete_news(id),
      method: "DELETE",
    });
    return {
      data,
      res,
    };
  }
}

export const NewsService = new News();
