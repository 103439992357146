import { h, resolveComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import store from "./store";
import { UsersService } from "@/services/users";
// Containers
const TheContainer = () => import("@/containers/TheContainer");
const AccountContainer = () => import("@/containers/account/AccountContainer");

// Views
const Home = () => import("@/views/Home");
const Page404 = () => import("@/views/Page404");
const Login = () => import("@/views/account/Login");
const ForgotPassword = () => import("@/views/account/ForgotPassword");
const ResetPassword = () => import("@/views/account/ResetPassword");
const Users = () => import("@/views/users/UsersPage");
const userNews = () => import("@/views/news/user/NewsUserPage");
const UseCase = () => import("@/views/UseCase");
const adminNews = () => import("@/views/news/admin/NewsAdminPage");
const NewsDetails = () => import("@/views/news/news-details/NewsDetails");

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes: configRoutes(),
});

const isOpenAccess = (route) =>
  route.matched.some((route) => route.meta?.isOpenAccess);

const isFound = (route) => route.matched[0].name !== "NotFound";

router.beforeEach(async (to, from, next) => {
  if (to.meta?.getTitle) to.meta.title = to.meta.getTitle(to);

  const isAuthenticated = await UsersService.get_userdata();
  const isUnauthorized =
    !isAuthenticated || isAuthenticated.detail === "Unauthorized";

  if (isUnauthorized && !isOpenAccess(to)) {
    if (isFound(to)) {
      localStorage.setItem("pathToLoadAfterLogin", to.path);
    }
    return next({ name: "Login" });
  } else {
    if (
      isAuthenticated &&
      isAuthenticated.detail !== "Unauthorized" &&
      Object.keys(isAuthenticated).length > 0
    ) {
      store.commit("setActiveUser", isAuthenticated);
      if (isAuthenticated.current_project.length > 0) {
        store.commit(
          "setCurrentSelectedProject",
          isAuthenticated.current_project
        );
      }
      const userIsSuperAdmin = isAuthenticated.is_super_admin;
      const isProjectAdmin = isAuthenticated.roles.some(
        (role) =>
          role.project_id === isAuthenticated.current_project && role.is_admin
      );
      if (!userIsSuperAdmin && !isProjectAdmin) {
        if (to.name === "Users" || to.name === "AdminNews") {
          return next({ name: "Home" });
        }
      }
    }
    await store.dispatch("fetchProjects");
    return next();
  }
});

export default router;

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "",
          name: "Home",
          component: Home,
        },
        {
          path: "users",
          name: "Users",
          component: Users,
        },
        {
          path: "use-case",
          name: "Use Case",
          component: UseCase,
        },
        {
          path: "/news",
          name: "News",
          component: userNews,
        },
        {
          path: "/news/:id",
          name: "NewsDetails",
          component: NewsDetails,
        },
        {
          path: "admin/news",
          name: "AdminNews",
          component: adminNews,
        },
      ],
    },
    {
      path: "/oauth",
      meta: {
        isOpenAccess: true,
      },
      component: AccountContainer,
      children: [
        {
          path: "",
          name: "OAuth Login",
          component: Login,
        },
      ],
    },
    {
      path: "/account",
      name: "account",
      meta: {
        isOpenAccess: true,
      },
      component: AccountContainer,
      children: [
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "forgot",
          name: "Forgot password",
          component: ForgotPassword,
        },
        {
          path: "reset/:token",
          name: "Password reset",
          component: ResetPassword,
        },
        {
          path: "login_management",
          name: "Management Login",
          component: Login,
          props: {
            show_email_login: true,
          },
        },
      ],
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render() {
          return h(resolveComponent("router-view"));
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      component: TheContainer,
      children: [
        {
          path: "",
          name: "",
          component: Home,
        },
      ],
    },
  ];
}
