import { request } from "@/utils/request";
import { BASE_URL } from "@/constants/config";

export const SUPPORT_ENDPOINTS = {
  create_category: `${BASE_URL}api/category/`,
  get_categories: (id) => `${BASE_URL}api/category/project/${id}/`,
  get_user_category: `${BASE_URL}api/category/user/`,
  update_category: (id) => `${BASE_URL}api/category/${id}/`,
  delete_category: (id) => `${BASE_URL}api/category/${id}/`,
};

class Category {
  async create_category(body) {
    const {data}  = await request({
      url: SUPPORT_ENDPOINTS.create_category,
      method: "POST",
      body: body,
    });
    return data
  }

  async get_categories(id) {
    const data = await request({
      url: SUPPORT_ENDPOINTS.get_categories(id),
      method: "POST",
      body:{}
    });
    return data;
  }
  async get_user_category() {
    const { data, res } = await request({
      url: SUPPORT_ENDPOINTS.get_user_category,
      method: "POST",
    });
    return {
      data,
      res,
    };
  }
  async update_category(body, id) {
    const { data} = await request({
      url: SUPPORT_ENDPOINTS.update_category(id),
      method: "PUT",
      body: body,
    });
    return data;
  }
  
  async delete_category(id) {
    const { data} = await request({
      url: SUPPORT_ENDPOINTS.delete_category(id),
      method: "DELETE",
    });
    return data
  }
 
}

export const CategoryService = new Category();
