import { BASE_URL } from "@/constants/config";
import { request } from "@/utils/request";

export const USER_ENDPOINTS = {
  // list: `${BASE_URL}api/users/`,
  managers: `${BASE_URL}api/users/managers/`,
  //register: `${BASE_URL}auth/register`,
  me: `${BASE_URL}users/me`,
  get_userdata: `${BASE_URL}api/users/data/me`,
  detail: (id) => `${BASE_URL}api/users/${id}/`,
  //roles: `${BASE_URL}api/roles/`,
  register_user: `${BASE_URL}api/users/`,
  get_all_users: `${BASE_URL}api/users/all`,
  forgot_password: `${BASE_URL}api/users/first_time_login`,
  reset_password: `${BASE_URL}api/users/reset_password`,
};

class Users {
  async get_userdata() {
    const { data } = await request({
      url: USER_ENDPOINTS.get_userdata,
      method: "GET",
    });
    return data;
  }

  async getManagers(page = 1, count = 10, text, level, countries, services) {
    /* 
    test: string,
    level: [string],
    countries: [string],
    services: [string],
    */
    const { data } = await request({
      url: USER_ENDPOINTS.managers,
      method: "POST",
      body: {
        page,
        count,
        text,
        level,
        countries,
        services,
      },
    });
    return data;
  }

  async getActiveUserInfo() {
    const { data } = await request({
      url: USER_ENDPOINTS.me,
    });

    return data;
  }

  async getUser(id) {
    const { data } = await request({
      url: USER_ENDPOINTS.detail(id),
    });
    return data;
  }

  async registerUser(userData) {
    const { data } = await request({
      url: USER_ENDPOINTS.register_user,
      method: "POST",
      body: userData,
    });
    return data;
  }

  async getAllUsers(payload) {
    const { data } = await request({
      url: USER_ENDPOINTS.get_all_users,
      method: "POST",
      body: payload,
    });
    return data;
  }

  async forgotPassword(email) {
    const { data } = await request({
      url: USER_ENDPOINTS.forgot_password,
      method: "POST",
      body: email,
    });
    return data;
  }

  async resetPassword(body) {
    const { data } = await request({
      url: USER_ENDPOINTS.reset_password,
      method: "POST",
      body: body,
    });
    return data;
  }
  /* async createUser(userData) {
    const { data } = await request({
      url: USER_ENDPOINTS.register,
      method: "POST",
      body: userData,
    });
    return data;
  }
 */
  /* async editUser(id, userData) {
    const { data } = await request({
      url: USER_ENDPOINTS.detail(id),
      method: "PATCH",
      body: userData,
    });
    return data;
  } */

  /* async deleteUser(id) {
    return await request({
      url: USER_ENDPOINTS.detail(id),
      method: "DELETE",
    });
  } */

  /* async getRoles() {
    const { data } = await request({
      url: USER_ENDPOINTS.roles,
    });
    return data;
  } */
}

export const UsersService = new Users();
